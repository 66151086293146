import React from 'react';
import { Backdrop, Box, Button, Typography, makeStyles, Fade, Divider } from '@material-ui/core';
import { X as Close, AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.text.primary,
  },
  backdropBox: {
    position: 'relative',
    backgroundColor: '#EFF0F2',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    width: 500,
    textAlign: 'left',
  },
  title: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
  message: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  deleteBtn: {
    textTransform: 'capitalize',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
    width: 20,
  },
}));

AreYouSureModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default function AreYouSureModal({ isOpen, title, message, onConfirm, onCancel }) {
  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <Backdrop open={isOpen} style={{ zIndex: 9999 }}>
      <Fade in={isOpen}>
        <Box className={classes.backdropBox}>
          <Close className={classes.closeIcon} onClick={onCancel} />
          <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom="10px">
            <AlertCircle color="#EC942C" />
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Divider />
          <Typography variant="body1" className={classes.message}>
            {message}
          </Typography>
          <Box className={classes.buttonBox}>
            <Button variant="outlined" className={classes.cancelBtn} onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" className={classes.deleteBtn} onClick={onConfirm}>
              Delete
            </Button>
          </Box>
        </Box>
      </Fade>
    </Backdrop>
  );
}
