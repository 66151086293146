import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { STORAGE } from 'src/lib/firebase';
import { deleteObject, ref, listAll } from 'firebase/storage';

const initialState = {
  logs: {
    isLoaded: true,
    docs: [],
  },
};

const slice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setDocs(state, action) {
      state.logs.docs = action.payload;
      state.logs.isLoaded = true;
    },

    startLoad(state, action) {
      state.logs.isLoaded = false;
    },

    finishLoad(state, action) {
      state.logs.isLoaded = true;
    },
  },
});

const serializeFiles = result => {
  return result.reduce(
    (acc, docs) =>
      acc.concat(
        docs.items.map(doc => {
          return {
            name: doc.name,
            path: doc._location.path,
          };
        }),
      ),
    [],
  );
};

export const reducer = slice.reducer;

const logStoragePaths = ['/logs', '/logs/auth', '/logs/non-auth'];

export const searchLogs = queryList => async dispatch => {
  dispatch(slice.actions.startLoad());

  const searchablePaths = _.flatten(
    queryList.map(query => logStoragePaths.map(path => `${path}/${query}`)),
  );

  const result = await Promise.all(
    searchablePaths.map(async path => {
      const listRef = ref(STORAGE, path);
      const res = await listAll(listRef);
      return res;
    }),
  );

  const data = serializeFiles(result);

  dispatch(slice.actions.setDocs(data));
  dispatch(slice.actions.finishLoad());
};

export const del = path => async dispatch => {
  dispatch(slice.actions.startLoad());

  const fileRef = ref(STORAGE, path);
  await deleteObject(fileRef);

  dispatch(slice.actions.finishLoad());
};

export default slice;
