import { find } from 'lodash';
import {
  ShoppingCart as ProductsIcon,
  FileText as DocsIcon,
  BarChart as BarChartIcon,
  MessageSquare as TicketsIcon,
  Users as UsersIcon,
  Gift as PromoCodesIcon,
  Globe as LanguagesIcon,
  FileMinus as LogsIcon,
  Image as MediaIcon,
  Package as PackageIcon,
  Activity as ActivityIcon,
  CreditCard as TransactionIcon,
  MessageCircle as FeedbackIcon,
  PhoneCall as ContactPageIcon,
  Briefcase as CareerPageIcon,
  Bookmark as CategoryIcon,
  Target as MotorSkillIcon,
  Film as VideoIcon,
  Music as LullabyAndSongIcon,
  Play as PlayIcon,
  Settings as ConfigIcon,
  UserCheck as WhitelistIcon,
} from 'react-feather';
import { PATHS_APP_OVERVIEW, PATHS_APPLICATION, PATHS_DOC, PATHS_FORM, PATHS_MANAGEMENT } from './routes/paths';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const GAME = {
  name: 'Sevimli Dostlar',
  platform:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? 'development (local)'
      : process.env.REACT_APP_NODE_ENV === 'staging'
      ? 'staging'
      : 'production',
};

export const COMPANY = {
  emailCheck: {
    regex: /^[A-Za-z0-9._%+-]+@(adisebaba.com|gamester.com.tr)$/,
    text: 'Only authorized emails',
  },
};

export const platformOptions = [
  {
    value: 'playstore',
    text: 'PlayStore',
  },
  {
    value: 'appstore',
    text: 'AppStore',
  },
];

export const getPlatformLabel = platform => {
  return find(platformOptions, { value: platform }).text;
};

export const SECTIONS = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: PATHS_APP_OVERVIEW.DASHBOARD,
      },
      {
        title: 'Activities',
        icon: ActivityIcon,
        href: PATHS_APP_OVERVIEW.ACTIVITIES,
      },
      {
        title: 'Transactions (AppsFlyer)',
        icon: TransactionIcon,
        href: PATHS_APP_OVERVIEW.TRANSACTIONS,
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: PATHS_MANAGEMENT.USERS.ROOT,
      },
      {
        title: 'Product Packages',
        icon: PackageIcon,
        href: PATHS_MANAGEMENT.PRODUCT_PACKAGES.ROOT,
      },
      {
        title: 'Products',
        icon: ProductsIcon,
        href: PATHS_MANAGEMENT.PRODUCTS.ROOT,
      },
      {
        title: 'Promo Codes',
        icon: PromoCodesIcon,
        href: PATHS_MANAGEMENT.PROMO_CODES.ROOT,
      },
      {
        title: 'Languages',
        icon: LanguagesIcon,
        href: PATHS_MANAGEMENT.LANGUAGES.ROOT,
      },
      {
        title: 'Media',
        icon: MediaIcon,
        href: '/app/management/media',
      },
      {
        title: 'Categories',
        icon: CategoryIcon,
        href: PATHS_MANAGEMENT.CATEGORIES.ROOT,
      },
      { title: 'Motor Skills', icon: MotorSkillIcon, href: PATHS_MANAGEMENT.MOTOR_SKILLS.ROOT },
      {
        title: 'Videos',
        icon: VideoIcon,
        href: PATHS_MANAGEMENT.VIDEOS.ROOT,
      },
      {
        title: 'Lullabies & Songs',
        icon: LullabyAndSongIcon,
        href: PATHS_MANAGEMENT.LULLABIES_AND_SONGS.ROOT,
      },
      {
        title: 'Game Types',
        icon: PlayIcon,
        href: PATHS_MANAGEMENT.GAME_TYPES.ROOT,
      },
      {
        title: 'Remote Configs',
        icon: ConfigIcon,
        href: PATHS_MANAGEMENT.REMOTE_CONFIGS.ROOT,
      },
      {
        title: 'Whitelist Users',
        icon: WhitelistIcon,
        href: PATHS_MANAGEMENT.WHITELIST_USERS.ROOT,
      },
    ],
  },
  {
    subheader: 'Application',
    items: [
      {
        title: 'Tickets',
        icon: TicketsIcon,
        href: PATHS_APPLICATION.TICKETS.ROOT,
      },
      {
        title: 'Logs',
        icon: LogsIcon,
        href: PATHS_APPLICATION.LOGS,
      },
    ],
  },
  {
    subheader: 'Forms',
    items: [
      {
        title: 'Feedback',
        icon: FeedbackIcon,
        href: PATHS_FORM.FEEDBACK,
      },
      {
        title: 'Contact Page',
        icon: ContactPageIcon,
        href: PATHS_FORM.CONTACT,
      },
      {
        title: 'Career Page',
        icon: CareerPageIcon,
        href: PATHS_FORM.CAREER,
      },
    ],
  },
  {
    subheader: 'Developer',
    items: [
      {
        title: 'API docs',
        icon: DocsIcon,
        href: PATHS_DOC.ROOT,
      },
    ],
  },
];
