import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { now } from 'src/utils/date';
import { collection, query, orderBy, doc, addDoc, getDoc, getDocs, setDoc, deleteDoc } from 'firebase/firestore';

const collectionName = 'motorSkills';

const initialState = {
  isLoaded: false,
  list: [],
  selected: null,
  error: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    data['createdAt'] = now();

    await addDoc(collection(DB, collectionName), data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  }
};

export const all = () => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    const q = query(collection(DB, collectionName), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);

    const list = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    dispatch(slice.actions.all(list));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    const docRef = doc(DB, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch(slice.actions.get({ id: docSnap.id, ...docSnap.data() }));
    } else {
      dispatch(slice.actions.setError('No such motor skill'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  }
  dispatch(slice.actions.endLoad());
};

export const update = data => async dispatch => {
  try {
    const { id } = data;
    data['updatedAt'] = now();

    await setDoc(doc(DB, collectionName, id), data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  }
};

export const remove = id => async dispatch => {
  try {
    await deleteDoc(doc(DB, collectionName, id));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(all());
  }
};

export const bulkRemove = ids => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    ids.map(async id => {
      await deleteDoc(doc(DB, collectionName, id));
    });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(all());
  }
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collectionName };
