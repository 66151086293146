import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { merge } from 'lodash';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';

const collectionName = 'whitelist';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    all(state, action) {
      state.list = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = () => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    const collectionRef = collection(DB, collectionName);
    const collectionQueryRef = query(collectionRef, orderBy('createdAt', 'desc'));

    const querySnapshot = await getDocs(collectionQueryRef);

    dispatch(slice.actions.all(querySnapshot.docs.map(doc => merge({ id: doc.id }, doc.data()))));
  } catch (err) {
    console.log(err);
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export default slice;
export { collectionName };
