import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { PATHS_AUTH } from 'src/routes/paths';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, error } = useAuth();

  if (error) {
    console.error(error);
  }

  if (!isAuthenticated) {
    return <Navigate to={PATHS_AUTH.LOGIN} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
