/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { GAME } from 'src/constants';
import NavbarLogo from 'src/components/NavbarLogo';
import { THEMES, SECTIONS } from 'src/constants';
import { ROOT_APP, PATHS_APP_OVERVIEW, PATHS_AUTH } from 'src/routes/paths';

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(
      {
        path: item.href,
      },
      pathname,
    );

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    paddingTop: 64,
  },
  avatar: {
    height: 70,
    cursor: 'pointer',
  },
  logoLayout: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    height: 40,
  },
  logoBackground: {
    ...(theme.name === THEMES.LIGHT && {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    }),
    ...(theme.name === THEMES.ONE_DARK && {
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center" className={classes.logoBackground}>
            <RouterLink to={PATHS_AUTH.ROOT}>
              <Logo className={classes.logo} height={40} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <RouterLink to={ROOT_APP} className={classes.logoLayout}>
              <NavbarLogo className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={1} textAlign="center">
            <Link component={RouterLink} to={PATHS_APP_OVERVIEW.ROOT} variant="h5" color="textPrimary" underline="none">
              {GAME.name}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {SECTIONS.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
