import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { now } from 'src/utils/date';
import {
  collection,
  query,
  orderBy,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';

const collectionName = 'languages';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    const { key, ...rest } = data;

    rest['createdAt'] = now();

    await setDoc(doc(DB, collectionName, key), rest);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const update = (id, data) => async dispatch => {
  try {
    data['updatedAt'] = now();

    setDoc(doc(DB, collectionName, id), data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = doc(DB, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists) {
      dispatch(slice.actions.get(_.merge({ id: docSnap.id }, docSnap.data())));
    } else {
      dispatch(slice.actions.setError('No such language'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = () => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const collectionRef = collection(DB, collectionName);
    const collectionQueryRef = query(
      collectionRef,
      orderBy('createdAt', 'desc'),
    );
    const docsRef = await getDocs(collectionQueryRef);

    dispatch(
      slice.actions.all(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const remove = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await deleteDoc(doc(DB, collectionName, id));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const bulkRemove = ids => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await Promise.all(
      ids.map(async id => await deleteDoc(doc(DB, collectionName, id))),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collectionName };
