import React, { createContext, useState } from 'react';
import AreYouSureModal from 'src/components/AreYouSureModal';

const AreYouSureContext = createContext();

const AreYouSureProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [onCancel, setOnCancel] = useState(() => () => {});

  const openModal = (title, message, onConfirmCallback, onCancelCallback) => {
    setTitle(title);
    setMessage(message);
    setOnConfirm(() => onConfirmCallback);
    setOnCancel(() => onCancelCallback);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <AreYouSureContext.Provider value={{ openModal, closeModal }}>
      <AreYouSureModal
        isOpen={isOpen}
        title={title}
        message={message}
        onConfirm={() => {
          onConfirm();
          closeModal();
        }}
        onCancel={() => {
          onCancel();
          closeModal();
        }}
      />
      {children}
    </AreYouSureContext.Provider>
  );
};

export { AreYouSureContext, AreYouSureProvider };
