import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import {
  getCountFromServer,
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
  query,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import { now } from 'src/utils/date';

const collectionName = 'videos';

const initialState = {
  isLoaded: false,
  list: [],
  count: 0,
  selected: null,
  error: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    const { videoId, ...rest } = data;
    rest['createdAt'] = now();
    rest['isPublished'] = false;
    rest['isProcess'] = false;
    rest['raw'] = data;

    await setDoc(doc(DB, collectionName, videoId), { videoId, ...rest });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const all = (filters, limitCount = 50) => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    let videoRefQuery = collection(DB, collectionName);

    if (filters.category && filters.category !== 'all') {
      videoRefQuery = query(videoRefQuery, where('category', 'array-contains', filters.category));
    }

    const videosByLimitQuery = query(videoRefQuery, orderBy('createdAt', 'desc'), limit(limitCount));

    const videosRef = await getDocs(videosByLimitQuery);

    const collectionSnapshot = await getCountFromServer(videoRefQuery);

    dispatch(
      slice.actions.all({
        list: videosRef.docs.map(doc => doc.data()),
        count: collectionSnapshot.data().count,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const getPage = (startAfterValue, filters, limitCount) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let videoRefQuery = collection(DB, collectionName);

    if (filters.category && filters.category !== 'all') {
      videoRefQuery = query(videoRefQuery, where('category', 'array-contains', filters.category));
    }

    videoRefQuery = query(videoRefQuery, orderBy('createdAt', 'desc'));

    if (startAfterValue) {
      videoRefQuery = query(videoRefQuery, startAfter(startAfterValue));
    }

    const docsRefQuery = query(videoRefQuery, limit(limitCount));
    const docsRef = await getDocs(docsRefQuery);

    dispatch(slice.actions.page(docsRef.docs.map(doc => doc.data())));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const get = videoId => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const videoRef = doc(DB, collectionName, videoId);
    const videoSnap = await getDoc(videoRef);

    if (videoSnap.exists()) {
      dispatch(slice.actions.get(videoSnap.data()));
    } else {
      dispatch(slice.actions.setError('No such video'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const update = (id, data) => async dispatch => {
  try {
    data['updatedAt'] = now();
    await setDoc(doc(DB, collectionName, id), data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  }
};

export const remove = (id, filters = null, limitCount = 50) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await deleteDoc(doc(DB, collectionName, id));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(all(filters, limitCount));
  }
};

export const bulkRemove = (ids, filters = null, limitCount = 50) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await Promise.all(ids.map(async id => await deleteDoc(doc(DB, collectionName, id))));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(all(filters, limitCount));
  }
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collectionName };
