import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { now } from 'src/utils/date';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, deleteDoc, orderBy, query } from 'firebase/firestore';

const collectionName = 'remoteConfigs';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = (userEmail, data) => async dispatch => {
  try {
    data['createdAt'] = now();

    await addDoc(collection(DB, collectionName), { ...data, lastModifiedBy: userEmail });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const update = (id, userEmail, data) => async dispatch => {
  try {
    data['updatedAt'] = now();

    await setDoc(doc(DB, collectionName, id), { ...data, lastModifiedBy: userEmail });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = doc(DB, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch(slice.actions.get(docSnap.data()));
    } else {
      dispatch(slice.actions.setError('No such document!'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const all = () => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const q = query(collection(DB, collectionName), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const list = querySnapshot.docs.map(doc => _.merge({ id: doc.id }, doc.data()));
    dispatch(slice.actions.all(list));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(slice.actions.endLoad());
  }
};

export const remove = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await deleteDoc(doc(DB, collectionName, id));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(all());
  }
};

export const bulkRemove = ids => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await Promise.all(ids.map(async id => await deleteDoc(doc(DB, collectionName, id))));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  } finally {
    dispatch(all());
  }
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collectionName };
