import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from 'firebase/firestore';

const collectionName = 'activities';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = (filters, limitCount = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let activityQueryRef = collection(DB, collectionName);

    if (filters.product && filters.product !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('product_id', '==', filters.product),
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('subscription_event', '==', filters.subscriptionEvent),
      );
    }

    if (filters.country && filters.country !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('country', '==', filters.country),
      );
    }

    const activitiesRefQuery = query(
      activityQueryRef,
      orderBy('created_at', 'desc'),
      limit(limitCount),
    );

    const activitiesRef = await getDocs(activitiesRefQuery);

    const collectionRef = await getDocs(activityQueryRef);

    dispatch(
      slice.actions.all({
        list: activitiesRef.docs.map(doc =>
          _.merge({ id: doc.id }, doc.data()),
        ),
        count: collectionRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (startAfter, filters, limitCount) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let activityQueryRef = collection(DB, collectionName);

    if (filters.product && filters.product !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('product_id', '==', filters.product),
      );
    }

    if (filters.country && filters.country !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('country', '==', filters.country),
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      activityQueryRef = query(
        activityQueryRef,
        where('subscription_event', '==', filters.subscriptionEvent),
      );
    }

    activityQueryRef = query(activityQueryRef, orderBy('created_at', 'desc'));

    if (startAfter) {
      activityQueryRef = query(
        activityQueryRef,
        startAfter(startAfter.created_at),
      );
    }

    const docsRefQuery = query(activityQueryRef, limit(limitCount));
    const docsRef = await getDocs(docsRefQuery);

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export default slice;
export { collectionName };
