import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { collection, doc, getDoc } from 'firebase/firestore';

const collectionName = 'overview';

const initialState = {
  error: null,
  dashboard: {
    isLoaded: false,
    data: null,
  },
  revenueChart: {
    isLoaded: false,
    chart: null,
  },
  trialsChart: {
    isLoaded: false,
    chart: null,
  },
  activeSubscriberBaseChart: {
    isLoaded: false,
    chart: null,
  },
  trialStartConversionChart: {
    isLoaded: false,
    chart: null,
  },
  trial2PaidConversionChart: {
    isLoaded: false,
    chart: null,
  },
  subscriptionConversionChart: {
    isLoaded: false,
    chart: null,
  },
  churnChart: {
    isLoaded: false,
    chart: null,
  },
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard.isLoaded = true;
      state.dashboard.data = action.payload;
    },
    setRevenueChart(state, action) {
      state.revenueChart.isLoaded = true;
      state.revenueChart.chart = action.payload;
    },
    setTrialsChart(state, action) {
      state.trialsChart.isLoaded = true;
      state.trialsChart.chart = action.payload;
    },
    setActiveSubscriberBaseChart(state, action) {
      state.activeSubscriberBaseChart.isLoaded = true;
      state.activeSubscriberBaseChart.chart = action.payload;
    },
    setTrialStartConversionChart(state, action) {
      state.trialStartConversionChart.isLoaded = true;
      state.trialStartConversionChart.chart = action.payload;
    },
    setTrial2PaidConversionChart(state, action) {
      state.trial2PaidConversionChart.isLoaded = true;
      state.trial2PaidConversionChart.chart = action.payload;
    },
    setSubscriptionConversionChart(state, action) {
      state.subscriptionConversionChart.isLoaded = true;
      state.subscriptionConversionChart.chart = action.payload;
    },
    setChurnChart(state, action) {
      state.churnChart.isLoaded = true;
      state.churnChart.chart = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchDashboard = () => async dispatch => {
  try {
    const docRef = doc(collection(DB, collectionName), 'dashboard');
    const dashboard = await getDoc(docRef);

    dispatch(slice.actions.setDashboard(dashboard.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchRevenueChart = () => async dispatch => {
  try {
    const docRef = doc(collection(DB, collectionName), 'revenueChart');
    const revenueChartData = await getDoc(docRef);

    dispatch(slice.actions.setRevenueChart(revenueChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrialsChart = () => async dispatch => {
  try {
    const docRef = doc(collection(DB, collectionName), 'trialsChart');
    const trialsChartData = await getDoc(docRef);

    dispatch(slice.actions.setTrialsChart(trialsChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchActiveSubscriberBaseChart = () => async dispatch => {
  try {
    const docRef = doc(
      collection(DB, collectionName),
      'activeSubscriberBaseChart',
    );
    const activeSubscriberBaseChartData = await getDoc(docRef);

    dispatch(
      slice.actions.setActiveSubscriberBaseChart(
        activeSubscriberBaseChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrialStartConversionChart = () => async dispatch => {
  try {
    const docRef = doc(
      collection(DB, collectionName),
      'trialStartConversionChart',
    );
    const trialStartConversionChartData = await getDoc(docRef);

    dispatch(
      slice.actions.setTrialStartConversionChart(
        trialStartConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrial2PaidConversionChart = () => async dispatch => {
  try {
    const docRef = doc(
      collection(DB, collectionName),
      'trial2PaidConversionChart',
    );
    const trial2PaidConversionChartData = await getDoc(docRef);

    dispatch(
      slice.actions.setTrial2PaidConversionChart(
        trial2PaidConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchSubscriptionConversionChart = () => async dispatch => {
  try {
    const docRef = doc(
      collection(DB, collectionName),
      'subscriptionConversionChart',
    );
    const subscriptionConversionChartData = await getDoc(docRef);

    dispatch(
      slice.actions.setSubscriptionConversionChart(
        subscriptionConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchChurnChart = () => async dispatch => {
  try {
    const docRef = doc(collection(DB, collectionName), 'churnChart');
    const churnChartData = await getDoc(docRef);

    dispatch(slice.actions.setChurnChart(churnChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export default slice;
export { collectionName };
