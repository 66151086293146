import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';

const collectionName = 'careerForms';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = (limitCount = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let formQueryRef = collection(DB, collectionName);

    const formQuery = query(
      formQueryRef,
      orderBy('createdAt', 'desc'),
      limit(limitCount),
    );
    const formRef = await getDocs(formQuery);

    const collectionRef = await getDocs(formQueryRef);

    dispatch(
      slice.actions.all({
        list: formRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
        count: collectionRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (startAfterValue, limitCount) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let formQueryRef = collection(DB, collectionName);

    formQueryRef = query(formQueryRef, orderBy('createdAt', 'desc'));

    if (startAfterValue) {
      formQueryRef = query(formQueryRef, startAfter(startAfterValue));
    }

    const docsQuery = query(formQueryRef, limit(limitCount));
    const docsRef = await getDocs(docsQuery);

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export default slice;
export { collectionName };
