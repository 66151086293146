import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { collection, doc, setDoc, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore';

const collectionName = 'tickets';

const initialState = {
  error: null,
  isLoaded: true,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = await getDoc(doc(DB, collectionName, id));

    if (docRef.exists) {
      const messagesRef = await getDocs(
        query(collection(DB, collectionName, id, 'messages'), orderBy('createdAt', 'asc')),
      );

      dispatch(
        slice.actions.get(
          _.merge({ id: docRef.id }, docRef.data(), {
            messages: messagesRef.docs.map(message => _.merge({ id: message.id }, message.data())),
          }),
        ),
      );
    } else {
      dispatch(slice.actions.setError('No such ticket'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = () => async dispatch => {
  try {
    const collectionRef = collection(DB, collectionName);
    const collectionQueryRef = query(collectionRef, where('isArchived', '==', false), orderBy('createdAt', 'asc'));
    const docsRef = await getDocs(collectionQueryRef);

    dispatch(slice.actions.all(docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data()))));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const archive = id => async dispatch => {
  try {
    const docRef = doc(DB, collectionName, id);
    await setDoc(docRef, { isArchived: true }, { merge: true });

    dispatch(slice.actions.reset());
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const resolve = (user, id) => async dispatch => {
  try {
    const docRef = doc(DB, collectionName, id);
    await setDoc(docRef, { isResolved: true, resolvedBy: user }, { merge: true });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export default slice;
export { collectionName };
