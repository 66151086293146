import { combineReducers } from '@reduxjs/toolkit';
// firestore
import { reducer as platformReducer, collectionName as platformCollection } from 'src/slices/platform';
import { reducer as productReducer, collectionName as productCollection } from 'src/slices/product';
import { reducer as promoCodeReducer, collectionName as promoCodeCollection } from 'src/slices/promoCode';
import {
  reducer as productPackageReducer,
  collectionName as productPackageCollection,
} from 'src/slices/productPackage';
import { reducer as languageReducer, collectionName as languageCollection } from 'src/slices/language';
import { reducer as translationReducer, collectionName as translationCollection } from 'src/slices/translation';
import { reducer as mediaReducer, collectionName as mediaCollection } from 'src/slices/media';
import { reducer as ticketReducer, collectionName as ticketCollection } from 'src/slices/ticket';
import { reducer as userReducer, collectionName as userCollection } from 'src/slices/users';
import { reducer as overviewReducer, collectionName as overviewCollection } from 'src/slices/overview';
import { reducer as documentReducer, collectionName as documentCollection } from 'src/slices/document';
import { reducer as activityReducer, collectionName as activityCollection } from 'src/slices/activity';
import { reducer as transactionReducer, collectionName as transactionCollection } from 'src/slices/transaction';
import { reducer as feedbackReducer, collectionName as feedbackCollection } from 'src/slices/feedback';
import { reducer as careerFormReducer, collectionName as careerFormCollection } from 'src/slices/careerForm';
import { reducer as contactFormReducer, collectionName as contactFormCollection } from 'src/slices/contactForm';
import { reducer as storageReducer } from 'src/slices/storage';
import { reducer as categoryReducer, collectionName as categoryCollection } from 'src/slices/category';
import { reducer as motorSkillReducer, collectionName as motorSkillCollection } from 'src/slices/motorSkill';
import { reducer as videoReducer, collectionName as videoCollection } from 'src/slices/video';
import { reducer as lullabyReducer, collectionName as lullabyCollection } from 'src/slices/lullaby';
import { reducer as gameReducer, collectionName as gameCollection } from 'src/slices/game';
import { reducer as remoteConfigReducer, collectionName as remoteConfigCollection } from 'src/slices/remoteConfig';
import { reducer as whitelistReducer, collectionName as whitelistCollection } from 'src/slices/whitelist';

const rootReducer = combineReducers({
  storage: storageReducer,
  [platformCollection]: platformReducer,
  [productCollection]: productReducer,
  [promoCodeCollection]: promoCodeReducer,
  [productPackageCollection]: productPackageReducer,
  [languageCollection]: languageReducer,
  [translationCollection]: translationReducer,
  [mediaCollection]: mediaReducer,
  [ticketCollection]: ticketReducer,
  [userCollection]: userReducer,
  [overviewCollection]: overviewReducer,
  [documentCollection]: documentReducer,
  [activityCollection]: activityReducer,
  [transactionCollection]: transactionReducer,
  [feedbackCollection]: feedbackReducer,
  [careerFormCollection]: careerFormReducer,
  [contactFormCollection]: contactFormReducer,
  [categoryCollection]: categoryReducer,
  [motorSkillCollection]: motorSkillReducer,
  [videoCollection]: videoReducer,
  [lullabyCollection]: lullabyReducer,
  [gameCollection]: gameReducer,
  [remoteConfigCollection]: remoteConfigReducer,
  [whitelistCollection]: whitelistReducer,
});

export default rootReducer;
