import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import { now } from 'src/utils/date';
import { collection, query, orderBy, doc, addDoc, getDoc, getDocs, setDoc, deleteDoc } from 'firebase/firestore';

const collectionName = 'categories';

const initialState = {
  isLoaded: false,
  list: [],
  selected: null,
  error: null,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    data['createdAt'] = now();

    await addDoc(collection(DB, collectionName), data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err));
  }
};

export const all = () => async dispatch => {
  try {
    dispatch(slice.actions.startLoad());

    const q = query(collection(DB, collectionName), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const list = [];

    querySnapshot.forEach(doc => {
      list.push({ ...doc.data(), id: doc.id });
    });

    dispatch(slice.actions.all(list));
    dispatch(slice.actions.endLoad());
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());

  try {
    const docRef = doc(DB, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch(slice.actions.get({ ...docSnap.data(), id: docSnap.id }));
    } else {
      slice.actions.setError('No such document!');
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const update = data => async dispatch => {
  try {
    const { id, ...rest } = data;
    rest['updatedAt'] = now();

    await setDoc(doc(DB, collectionName, id), rest);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const remove = id => async dispatch => {
  try {
    await deleteDoc(doc(DB, collectionName, id));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const bulkRemove = ids => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await Promise.all(ids.map(async id => await deleteDoc(doc(DB, collectionName, id))));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const checkExist = category => async dispatch => {
  try {
    dispatch(slice.actions.setError(null));
    const q = query(collection(DB, collectionName));
    const querySnapshot = await getDocs(q);
    const list = [];

    querySnapshot.forEach(doc => {
      list.push({ ...doc.data(), id: doc.id });
    });

    const isExist = list.find(item => item.name.toLowerCase() === category.toLowerCase());
    if (isExist) {
      dispatch(slice.actions.setError('The Category already exists!'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collectionName };
