import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { Container, makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import components from './mdx';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    paddingTop: 64,
    paddingLeft: 256,
  },
  contentContainer: {
    overflow: 'auto',
  },
  content: {},
}));

const DocsLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <Container maxWidth={false} className={classes.content}>
            <MDXProvider components={components}>{children}</MDXProvider>
          </Container>
        </div>
      </div>
      <Outlet />
    </>
  );
};

DocsLayout.propTypes = {
  children: PropTypes.node,
};

export default DocsLayout;
