import React from 'react';

const NavbarLogo = props => {
  return (
    <img
      alt="Logo"
      src="/static/logo-navbar.png"
      {...props}
      style={{ borderRadius: '50%' }}
    />
  );
};

export default NavbarLogo;
