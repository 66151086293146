import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes, connectStorageEmulator } from 'firebase/storage';
import { EMULATOR_CONFIG, FIREBASE_API } from 'src/config';

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const STORAGE = getStorage(firebaseApp);

if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  connectAuthEmulator(AUTH, EMULATOR_CONFIG.AUTH);
  connectFirestoreEmulator(DB, EMULATOR_CONFIG.FIRESTORE.host, EMULATOR_CONFIG.FIRESTORE.port);
  connectStorageEmulator(STORAGE, EMULATOR_CONFIG.STORAGE.host, EMULATOR_CONFIG.STORAGE.port);
}

export { AUTH, DB, STORAGE };

export const sendFileToStorage = async (file, filePath) => {
  const storageRef = ref(STORAGE, filePath);

  const result = await uploadBytes(storageRef, file);

  return result.metadata.fullPath;
};

export const getFromStorage = async filePath => {
  const storageRef = ref(STORAGE, filePath);

  const url = await getDownloadURL(storageRef);

  return url;
};
