import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  where,
} from 'firebase/firestore';

const collectionName = 'transactions';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = (filters, limitCount = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let transactionQueryRef = collection(DB, collectionName);

    if (filters.product && filters.product !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('productId', '==', filters.product),
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('subscriptionEventType', '==', filters.subscriptionEvent),
      );
    }

    if (filters.country && filters.country !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('country', '==', filters.country),
      );
    }

    if (filters.currency && filters.currency !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('currency', '==', filters.currency),
      );
    }

    const transactionRefQuery = query(
      transactionQueryRef,
      orderBy('eventTime', 'desc'),
      limit(limitCount),
    );
    const transactionsRef = await getDocs(transactionRefQuery);

    const collectionRef = await getDocs(transactionQueryRef);

    dispatch(
      slice.actions.all({
        list: transactionsRef.docs.map(doc =>
          _.merge({ id: doc.id }, doc.data()),
        ),
        count: collectionRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (
  startAfterValue,
  filters,
  limitCount,
) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let transactionQueryRef = collection(DB, collectionName);

    if (filters.product && filters.product !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('productId', '==', filters.product),
      );
    }

    if (filters.country && filters.country !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('country', '==', filters.country),
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      transactionQueryRef = query(
        transactionQueryRef,
        where('subscriptionEventType', '==', filters.subscriptionEvent),
      );
    }

    transactionQueryRef = query(
      transactionQueryRef,
      orderBy('eventTime', 'desc'),
    );

    if (startAfterValue) {
      transactionQueryRef = query(
        transactionQueryRef,
        startAfter(startAfterValue.eventTime),
      );
    }

    const docsRefQuery = query(transactionQueryRef, limit(limitCount));
    const docsRef = await getDocs(docsRefQuery);

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export default slice;
export { collectionName };
